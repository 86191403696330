<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item">
        <DefaultInput
            :label="'Id'"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['insuranceSkladUsa_countryGroup'])"></span>

        <DefaultSelect
            :options="options"
            :label="$t('insuranceSkladUsa_countryGroup.localization_value.value')"
            :selected="getCountrySelected()"
            :optionsLabel="'name'"
            @change="changeCountry"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['insuranceSkladUsa_shippingCompany'])"></span>

        <DefaultSelect
            :options="optionsShippingCompanies"
            :label="$t('insuranceSkladUsa_shippingCompany.localization_value.value')"
            :selected="getShippingCompanySelected()"
            :optionsLabel="'name'"
            @change="changeShippingCompany"
        />
      </div>

      <!--<div class="table-filter__item">-->
        <!--<DefaultInput-->
            <!--:label="$t('common_comment.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="insuranceType"-->
        <!--/>-->
      <!--</div>-->

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "InsuranceSkladUsaFilter",
    components: {
      DefaultSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        id: this.filterGetParams.iossId ? this.filterGetParams.id : '',
        countryId: this.filterGetParams.countryId ? this.filterGetParams.countryId : '',
        shippingCompany: this.filterGetParams.marketplace ? this.filterGetParams.shippingCompany : '',
        shippingCompanyId: this.filterGetParams.comment ? this.filterGetParams.shippingCompanyId : '',
        insuranceType: this.filterGetParams.number ? this.filterGetParams.insuranceType : '',

        options: [],
        optionsShippingCompanies: [],

        filterCounts: [
          'id',
          'countryId',
          'shippingCompany',
          'shippingCompanyId',
          'insuranceType',
        ],
      }
    },

    mounted() {
      this.$store.dispatch('getInsuranceSkladUsaCreate').then(response => {
        let createData = this.getRespData(response)
        this.options = createData?.from_country_groups.data
        this.optionsShippingCompanies = createData?.shipping_companies
      }).catch(error => this.createErrorLog(error))
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.countryId = newVal.countryId ? newVal.countryId : ''
        this.shippingCompany = newVal.shippingCompany ? newVal.shippingCompany : ''
        this.shippingCompanyId = newVal.shippingCompanyId ? newVal.shippingCompanyId : ''
        this.insuranceType = newVal.insuranceType ? newVal.insuranceType : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading)
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      getCountrySelected(){
        if(this.countryId === '') return ''
        return this._.find(this.options, {id: parseInt(this.countryId)})
      },

      changeCountry(val) {
        this.countryId = val.id
      },

      getShippingCompanySelected(){
        if(this.shippingCompanyId === '') return ''
        return this._.find(this.optionsShippingCompanies, {id: parseInt(this.shippingCompanyId)})
      },

      changeShippingCompany(val) {
        this.shippingCompanyId = val.id
      },

    },
  }
</script>

<style scoped>

</style>
