<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <!--<DefaultCheckbox-->
            <!--class="mt-1"-->
            <!--:selectedNow="selectedNow"-->
            <!--:dataValue="item.id"-->
        <!--/>-->
        <div class="table-card__item-number d-flex">
          <router-link
              :to="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa + '/show/' + item.id"
              class="table-link btn-style"
          >
            #{{item.id}}
          </router-link>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('insuranceSkladUsa_created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('insuranceSkladUsa_name.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.name}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('insuranceSkladUsa_price.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.price }}
            </div>
          </div>

          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('insuranceSkladUsa_countryGroup.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'from_country_group.name'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('insuranceSkladUsa_shippingCompany.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'shipping_company.name'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                v-if="isAdmin"
                :value="$t(`common_edit.localization_value.value`)"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa + '/edit/' + item.id"
            />
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem', item.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "InsuranceSkladUsaTableMobile",

    components: {
      ValueHelper,
      LinkButton,
    },

    props: {
      item: Object,
      statusTranslation: String,
      commentsArray: Array,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false
      }
    },

    methods: {

    },

  }
</script>

<style scoped>

</style>
