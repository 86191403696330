<template>
  <InsuranceSkladUsaTableUser
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"

      @filter="filter"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @reloadTable="filter"
  />
</template>

<script>
  import InsuranceSkladUsaTableUser from "./InsuranceSkladUsaTableUser/InsuranceSkladUsaTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {InsuranceSkladUsaMixin} from "../../../../../mixins/insuranceSkladUsaMixins/insuranceSkladUsaMixin";

  export default {
    name: "InsuranceSkladUsaTable",

    components: {
      InsuranceSkladUsaTableUser,
    },

    mixins: [routeFilter, InsuranceSkladUsaMixin],

    data(){
      return {
        forPage: this.$store.getters.getInsuranceSkladUsaForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterCountryId: '',
        filterShippingCompany: '',
        filterShippingCompanyId: '',
        filterInsuranceType: '',

        countFilterParams: 0,
        filterGetParams: {},

        createData: null,
      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          id: this.$route.query.id,
          countryId: this.$route.query.countryId,
          shippingCompany: this.$route.query.shippingCompany,
          shippingCompanyId: this.$route.query.shippingCompanyId,
          insuranceType: this.$route.query.insuranceType,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      resetFilter() {
        this.$router.push({path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextInsuranceSkladUsaPage', true)

        // save filter params in store
        this.$store.commit('setInsuranceSkladUsaFilter', window.location.search)

        this.$store.dispatch('fetchInsuranceSkladUsa', url).then(() => {
          // skip get next page
          this.$store.commit('setNextInsuranceSkladUsaPage', false)
        })

        this.checkCountFilter(['userName', 'complaintTypeName', 'reasonsName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterCountryId: 'FromCountryGroupId',
              filterShippingCompanyId: 'ShippingCompanyId',
              filterInsuranceType: 'InsuranceType',
            },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },
    }
  }
</script>

<style scoped>

</style>
