<template>
  <div class="table-filter-wrap">
    <template
        v-if="$store.getters.getInsuranceSkladUsa.length > 0 && $store.getters.getInsuranceSkladUsaLoading !== true">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'insuranceSkladUsa_id',
            'insuranceSkladUsa_created',
            'insuranceSkladUsa_name',
            'insuranceSkladUsa_price',
            'insuranceSkladUsa_countryGroup',
            'insuranceSkladUsa_shippingCompany',
            'common_manage',
            ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <!--<th></th>-->
            <th>{{$t('insuranceSkladUsa_id.localization_value.value')}}</th>
            <th>{{$t('insuranceSkladUsa_created.localization_value.value')}}</th>
            <th>{{$t('insuranceSkladUsa_name.localization_value.value')}}</th>
            <th>{{$t('insuranceSkladUsa_price.localization_value.value')}}</th>
            <th>{{$t('insuranceSkladUsa_countryGroup.localization_value.value')}}</th>
            <th>{{$t('insuranceSkladUsa_shippingCompany.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getInsuranceSkladUsa" :key="index">
            <!--<td>-->
              <!--<DefaultCheckbox-->
                  <!--class="empty-label mt-1"-->
                  <!--:selectedNow="selectedNow"-->
                  <!--:dataValue="item.id"-->
              <!--/>-->
            <!--</td>-->
            <td>
              <div class="d-flex align-items-center">
                <router-link
                  :to="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa + '/show/' + item.id"
                  class="table-link btn-style"
                >
                  #{{item.id}}
                </router-link>
              </div>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              {{item.name}}
            </td>
            <td>
              {{ item.price }}
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'from_country_group.name'"
              />
            </td>
            <td>
              <ValueHelper
                      :value="item"
                      :deep="'shipping_company.name'"
              />
            </td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'common_react',
                'common_edit',
                ])"></div>
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        v-if="isAdmin"
                        :value="$t(`common_edit.localization_value.value`)"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa + '/edit/' + item.id"
                    />
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeItem(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>



          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getInsuranceSkladUsa"
                 :key="index"
            >
              <InsuranceSkladUsaTableMobile
                  :item="item"
                  :index="index"
                  :selectedNow="selectedNow"
                  @removeItem="removeItem"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getInsuranceSkladUsaCommonList.next_page_url !== null && $store.getters.getInsuranceSkladUsa.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextInsuranceSkladUsaPage}"
                :count="$store.getters.getInsuranceSkladUsaCommonList.total - $store.getters.getInsuranceSkladUsaForPage < $store.getters.getInsuranceSkladUsaForPage ?
                    $store.getters.getInsuranceSkladUsaCommonList.total - $store.getters.getInsuranceSkladUsaForPage:
                    $store.getters.getInsuranceSkladUsaForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template
        v-if="$store.getters.getInsuranceSkladUsaLoading === false && $store.getters.getInsuranceSkladUsa.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import {PAYPAL_CASES_ARRAY_STATUSES} from "../../../../../../../staticData/staticVariables";
  import InsuranceSkladUsaTableMobile from "./InsuranceSkladUsaTableMobile/InsuranceSkladUsaTableMobile";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";

  export default {
    name: "InsuranceSkladUsaTable",

    components: {
      NoResult,
      ValueHelper,
      InsuranceSkladUsaTableMobile,
      ShowMore,
      LinkButton,
      ManagerButton,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      selectedNow: Boolean,
    },

    data() {
      return {
        PAYPAL_CASES_ARRAY_STATUSES: PAYPAL_CASES_ARRAY_STATUSES,
        isModalInsuranceSkladUsaToWarehouse: false,
        isModalRequestRefundsPopup: false,
        openedComplaintId: null,
      }
    },

    methods: {
      removeItem(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteInsuranceSkladUsa', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                this.$emit('close')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      }
    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 165px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  .clipping-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  td:first-child{
    max-width: 33px;
  }

  td:last-child{
    max-width: 120px;
  }

  .comment-item{
    margin: 0 -15px;
    padding: 10px 15px;
    display: block;

    &:nth-child(even){
      background: #F8F4EE;
    }

    &--right-align {
      text-align: right;
    }

    p{
      margin-bottom: 0;
      &:first-child{
        margin-bottom: 5px;
      }
    }
  }

</style>
