<template>
  <div>
    <InsuranceSkladUsaHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        @selectAll="selectAll"
        @reloadTable="$emit('reloadTable')"
    />

    <div class="table-filter-wrap">

      <InsuranceSkladUsaFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
      />

      <InsuranceSkladUsaTable
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          :selectedNow="selectedNow"
      />
    </div>
  </div>
</template>

<script>


  import InsuranceSkladUsaHead from "./InsuranceSkladUsaHead/InsuranceSkladUsaHead";
  import InsuranceSkladUsaTable from "./InsuranceSkladUsaTable/InsuranceSkladUsaTable";
  import InsuranceSkladUsaFilter from "./InsuranceSkladUsaFilter/InsuranceSkladUsaFilter";

  export default {
    name: "InsuranceSkladUsaTableUser",

    components: {
      InsuranceSkladUsaHead,
      InsuranceSkladUsaTable,
      InsuranceSkladUsaFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',

        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        console.log(data);
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
